import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";

const OBBTvComponent = () => {
    const playerRef = useRef(null);
    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.seekTo(0);
        }

        const intervalId = setInterval(() => {
            setRenderKey(prevKey => prevKey + 1);
        }, 900000); // Change 10000 to the desired interval in milliseconds

        return () => clearInterval(intervalId);
    }, []);

    const onReady = React.useCallback(() => {
        const timeToStart = 0;
        playerRef.current.seekTo(timeToStart, 'seconds');
    }, [playerRef.current]);

    return (
        <div className={"player-wrapper"} key={renderKey}>
            <ReactPlayer
                playing={true}
                onStart={() => console.log('onStart')}
                onPlay={() => console.log('onPlay')}
                onPause={() => console.log('onPause')}
                onBuffer={() => console.log('onBuffer')}
                onEnded={() => console.log('onEnded')}
                onError={() => console.log('onError')}
                onReady={onReady}
                playsinline={false}
                controls={false}
                loop={false}
                muted={true}
                className='react-player'
                width="100%"
                height={"100%"}
                ref={playerRef}
                url='https://cdn1-ordubeltv.yayin.com.tr/ordubeltv/ordubeltv/playlist.m3u8'
            />
        </div>
    );
}

export default OBBTvComponent;