import HomePage from "./pages/Home/HomePage";
import "../src/styles/Style.sass"
import {RaspiDeviceProvider} from "./context/RaspiDeviceContext";
import {OledSocketProvider} from "./context/OledSocketContext";

const App = () => {
  return(
      <RaspiDeviceProvider>
          <OledSocketProvider>
              <HomePage/>
          </OledSocketProvider>
      </RaspiDeviceProvider>
  )
}

export default App