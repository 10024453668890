import {Col, Row} from "antd";
import OBBTvComponent from "../../components/OBBTvComponent";
import NewsRightPanelComponent from "../../components/NewsRightPanelComponent";
import WeatherComponent from "../../components/WeatherComponent";
import {RaspiDeviceContext} from "../../context/RaspiDeviceContext";
import {useContext} from "react";
import WidgetNobetciEczaneComponent from "../../components/WidgetNobetciEczaneComponent";

const HomePage = () => {
    const {raspiDeviceSerialNumber} = useContext(RaspiDeviceContext)

    return(<div style={{padding:20}}>
        <Row gutter={[10,10]}>
            <Col span={18}>
                <Row gutter={[10,10]}>
                    <Col span={24}>
                        <OBBTvComponent></OBBTvComponent>
                    </Col>
                    <Col span={24}>
                        <WidgetNobetciEczaneComponent></WidgetNobetciEczaneComponent>
                    </Col>
                    <Col span={24}>
                        <WeatherComponent></WeatherComponent>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <NewsRightPanelComponent></NewsRightPanelComponent>
                {<h1 style={{fontSize:16, color:"white"}}>{raspiDeviceSerialNumber}</h1>}
            </Col>
        </Row>
    </div>)
}

export default HomePage