import React, {useEffect, useState} from "react";
import moment from "moment/moment";

const WidgetCurrentDate = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // 1000 milisaniye (1 saniye) aralıklarla güncelle
        return () => clearInterval(intervalId); // component unmount olduğunda interval'i temizle
    }, []);

    return(
        <>
                {moment(currentTime).format('LL')} {moment(currentTime).format('LT')}
        </>
    )
}

export default WidgetCurrentDate
