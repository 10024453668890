import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import WidgetCurrentDate from "./WidgetCurrentDate";
import {useEffect, useState} from "react";
import axios, {get} from "axios";
const WeatherComponent = () => {
    const [district, setDistrict] = useState([]);

    useEffect(() => {
        getData()

        const intervalId = setInterval(() => {
            getData();
        }, 1200000); // 10 dakika = 10 * 60 * 1000 milisaniye

        // Unmounting logic
        return () => {
            clearInterval(intervalId); // Temizleme işlemi
        };
    }, []);

    const getData = () => {
        axios.get("https://service.orbel.com.tr/hava-durumu/ilceler.php?il=Ordu")
            .then(async res => {
                let resData = await Promise.all(res.data.map(async (item, index) => {
                    try {
                        let response = await axios.get("https://service.orbel.com.tr/hava-durumu/merkez.php?merkezid="+item.merkezId);
                        item.sicaklik = response.data[0]?.sicaklik;
                        item.hadiseKodu = response.data[0]?.hadiseKodu;
                        return item;
                    } catch (error) {
                        return item;
                    }
                }));

                setDistrict(resData)
            })
            .catch(err => {
                console.error("Hata oluştu:", err);
            });
    }

    return(<div className={"weather-widget"}>
        <div>
            <div className={"weather-district"}>
                <div className={"district-temp"}>{Math.round(district[1]?.sicaklik)}°</div>
                <div className={"district-name"}>
                    <div className={"district-name-title"}>
                        İl Geneli
                    </div>
                    <div className={"district-name-desc"}>

                    </div>
                </div>
                <div className={"district-icon"}>
                    <img src={process.env.PUBLIC_URL + `/assets/images/hadise/${district[1]?.hadiseKodu}.svg`}
                         alt={"il geneli"}></img>
                </div>
            </div>
            <div style={{marginTop: -50, textAlign: "center", fontSize:28, color:"#00419E", fontWeight:"bold"}}>
                <WidgetCurrentDate/>
            </div>
        </div>


        <Swiper
            spaceBetween={14}
            slidesPerView={5}

            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}

            modules={[Autoplay, Pagination, Navigation]}
            className="weather-city"
        >
            {district.map((item,index) => {
                return(
                    <SwiperSlide className={"city-item"} key={index}>
                        <div className={"city-name"}>
                            <p>{item?.ilce}</p>
                        </div>
                        <div className={"city-temp-icon"}>
                            <img src={process.env.PUBLIC_URL + `/assets/images/hadise/${item.hadiseKodu}.svg`} alt={item.hadiseKodu}></img>
                        </div>
                        <div className={"city-temp"}>
                            {Math.round(item?.sicaklik)}°
                        </div>
                    </SwiperSlide>
                )
            })}




        </Swiper>
    </div>)
}

export default WeatherComponent;