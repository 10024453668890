import {useEffect, useState} from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
const NewsRightPanelComponent = () => {
    const [data,setData] = useState([]);

    const getData = () => {
        axios.get("https://ordu.bel.tr/news/getlistnews?&page=1&pageSize=20").then(res => {
            setData(res.data.data.news)
        }).catch(err => {})
    }

    useEffect(() => {
        getData()

        const intervalId = setInterval(() => {
            getData();
        }, 1200000);

        // Unmounting logic
        return () => {
            clearInterval(intervalId);
        };
    }, []);
    return(<div className={"news-widget"}>

        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
                delay: 10000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}

            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
            {data.map((item,index) => {
                return(<SwiperSlide className={"news-detail"} key={item.id} id={item.id}>
                        <div className={"news-image"}
                             style={{backgroundImage: `url('https://www.ordu.bel.tr/uplouds/news/${item.coverImage}')`}}>
                        </div>
                        <div className={"news-title"}>
                            <p>{item.headline}</p>
                        </div>
                        <div className={"news-desc"}>
                            <p>{item.shortDescription}</p>
                        </div>
                        <div className={"news-share"}>
                            <div className={"news-share-title"}>
                                Haberlerin detayları www.ordu.bel.tr adresinde...
                            </div>
                        </div>
                </SwiperSlide>)
            })}
        </Swiper>

    </div>)
}

export default NewsRightPanelComponent