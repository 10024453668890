import {createContext, useContext, useEffect, useState} from "react";
import { io } from 'socket.io-client';
import {OLED_SOCKET_API} from "../services/Api";
import {message} from "antd";
import {RaspiDeviceContext} from "./RaspiDeviceContext";

const OledSocketContext = createContext();

const OledSocketProvider = ({children}) => {
    const [isConnected, setIsConnected] = useState(false);
    const [socket, setSocket] = useState(null);
    const [socketId, setSocketId] = useState(null);
    const {raspiDeviceSerialNumber} = useContext(RaspiDeviceContext);

    useEffect(() => {
        const socket = io(OLED_SOCKET_API);
        setSocket(socket);
        const deviceSerialNumber = raspiDeviceSerialNumber;

        //socket bağlandığında yapılacak işlemler
        socket.on("connect", () => {
            setIsConnected(true);
            // message.success("Socket bağlantısı başarıyla kuruldu.");
            // message.success("Socket ID: " + socket.id);
            message.success("Raspi Device Serial Number1: " + deviceSerialNumber);

            //yeni bir kullanıcı bağlandığında sunucuya gönder
            const user = {name: "Raspi-1", serial: deviceSerialNumber};
            socket.emit("new-user", user);
        });

        //socket bağlantısı kesildiğinde yapılacak işlemler
        socket.on("disconnect", () => {
            setIsConnected(false);
            message.error("Socket bağlantısı kesildi. Yeniden bağlanmaya çalışılıyor...");
        });

        socket.on("me", () => {
            const user = {name: "Raspi-1", serial: raspiDeviceSerialNumber};
            socket.emit("new-user", user);
            console.log("oled_tv_me_istediler")
        });

        socket.on("pageReload", (id) => {
            message.error("wvet söylediğin gibi!!!"+id)
            window.location.reload();
        });

        //birden fazla socket bağlantısı açılmasını engellemek için
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };

    }, []);

    useEffect(() => {
        if(socket == null) return;

        const user = {name: "Raspi-1", serial: raspiDeviceSerialNumber};
        socket.emit("new-user", user);
    }, [raspiDeviceSerialNumber]);

    return (
        <OledSocketContext.Provider value={{
            socket,
            isConnected
        }}>
            {children}
        </OledSocketContext.Provider>
    );
}

export {OledSocketProvider, OledSocketContext}