import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {message} from "antd";
import {RASPI_API} from "../services/Api";

const RaspiDeviceContext = createContext();

const RaspiDeviceProvider = ({children}) => {
    const [device, setDevice] = useState(null);
    const [raspiDeviceSerialNumber, setRaspiDeviceSerialNumber] = useState(null);

    useEffect(() => {
        getRaspiDeviceSerialNumber();
    }, []);

    const getRaspiDeviceSerialNumber = () => {
        axios.get(RASPI_API+"/api/serial").then(res => {
            message.success("Cihaz seri numarası alındı.");
            message.success("Cihaz seri numarası: " + res.data.serial);
            setRaspiDeviceSerialNumber(res.data.serial)
        }).catch(err => {
            message.error("Cihaz seri numarası alınamadı.");
        })
    }

    return (
        <RaspiDeviceContext.Provider value={
            {
                device,
                setDevice,
                raspiDeviceSerialNumber,
                setRaspiDeviceSerialNumber
            }
        }>
            {children}
        </RaspiDeviceContext.Provider>
    );
}


export {RaspiDeviceProvider, RaspiDeviceContext}